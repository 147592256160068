var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Default"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeAlertDefaut) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_vm._v("Alerts are available for any length of text, as well as an optional dismiss button (and optional auto-dismissing).")]), _c('div', {
    staticClass: "demo-spacing-0"
  }, [_c('b-alert', {
    attrs: {
      "variant": "primary",
      "show": ""
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_c('span', [_c('strong', [_vm._v("Good Morning!")]), _vm._v(" Start your day with some alerts.")])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }