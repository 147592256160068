var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card-code', {
    attrs: {
      "title": "Colors"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeAlertColor) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("For proper styling of ")]), _c('code', [_vm._v("<b-alert>")]), _c('span', [_vm._v(" , use one of the four required contextual variants by setting the ")]), _c('code', [_vm._v("variant")]), _c('span', [_vm._v(" prop to one of the following: ")]), _c('code', [_vm._v("info, success, warning")]), _c('span', [_vm._v(" or ")]), _c('code', [_vm._v("danger")]), _c('span', [_vm._v(" . The default is ")]), _c('code', [_vm._v("info")]), _c('span', [_vm._v(" .")])]), _c('div', {
    staticClass: "demo-spacing-0"
  }, [_c('b-alert', {
    attrs: {
      "variant": "primary",
      "show": ""
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v(" Primary ")]), _c('div', {
    staticClass: "alert-body"
  }, [_c('span', [_vm._v("Tootsie roll lollipop lollipop icing. Wafer cookie danish macaroon. Liquorice fruitcake apple pie I love cupcake cupcake.")])])]), _c('b-alert', {
    attrs: {
      "variant": "secondary",
      "show": ""
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v(" Secondary ")]), _c('div', {
    staticClass: "alert-body"
  }, [_c('span', [_vm._v("Tootsie roll lollipop lollipop icing. Wafer cookie danish macaroon. Liquorice fruitcake apple pie I love cupcake cupcake.")])])]), _c('b-alert', {
    attrs: {
      "variant": "success",
      "show": ""
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v(" Success ")]), _c('div', {
    staticClass: "alert-body"
  }, [_c('span', [_vm._v("Tootsie roll lollipop lollipop icing. Wafer cookie danish macaroon. Liquorice fruitcake apple pie I love cupcake cupcake.")])])]), _c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": ""
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v(" Danger ")]), _c('div', {
    staticClass: "alert-body"
  }, [_c('span', [_vm._v("Tootsie roll lollipop lollipop icing. Wafer cookie danish macaroon. Liquorice fruitcake apple pie I love cupcake cupcake.")])])]), _c('b-alert', {
    attrs: {
      "variant": "warning",
      "show": ""
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v(" Warning ")]), _c('div', {
    staticClass: "alert-body"
  }, [_c('span', [_vm._v("Tootsie roll lollipop lollipop icing. Wafer cookie danish macaroon. Liquorice fruitcake apple pie I love cupcake cupcake.")])])]), _c('b-alert', {
    attrs: {
      "variant": "info",
      "show": ""
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v(" Info ")]), _c('div', {
    staticClass: "alert-body"
  }, [_c('span', [_vm._v("Tootsie roll lollipop lollipop icing. Wafer cookie danish macaroon. Liquorice fruitcake apple pie I love cupcake cupcake.")])])]), _c('b-alert', {
    attrs: {
      "variant": "dark",
      "show": ""
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v(" Dark ")]), _c('div', {
    staticClass: "alert-body"
  }, [_c('span', [_vm._v("Tootsie roll lollipop lollipop icing. Wafer cookie danish macaroon. Liquorice fruitcake apple pie I love cupcake cupcake.")])])])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }